import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';


const { confirm } = Modal

const dialogConfirm = {
  confirmDelete: ({title, content, onOk, onCancel}) => {

    confirm({
      title    : title,
      content  : content || undefined,
      icon     : <ExclamationCircleOutlined />,
      onOk     : onOk,
      onCancel : onCancel,
      zIndex   : 2002,
      centered : true,
      okType   : 'danger',
      okText   : 'Confirmar',
      cancelText: 'Cancelar',
    })

  },
  confirmQuestion: ({title, content, onOk, onCancel}) => {

    confirm({
      title    : title,
      content  : content || undefined,
      icon     : <QuestionCircleFilled />,
      onOk     : onOk,
      onCancel : onCancel,
      zIndex   : 2002,
      centered : true,
      okType   : 'default',
      okText   : 'Confirmar',
      cancelText: 'Cancelar',
    })

  }
}

export default dialogConfirm