import React from "react";
import { Card } from 'antd'

export function DashboardPage() {
  return <div>
    <Card title='Dashboard' >
      
    </Card>
  </div>
}
