const prefix = 'adm_sigem';

var _getModules = (complete) => {
	try{
		var strMods = localStorage.getItem(prefix+'_user_modules');
		if(strMods &&  strMods !== "undefined"){
			var mods = JSON.parse(strMods) || [];
			return complete ? mods : mods.map((item)=>{ return item.identifier; });
		}else{
			return [];
		}
	}catch(error){
		console.log(error);
		return [];
	}
};

const session = {
	clear: function(){
		// localStorage.clear();
		localStorage.removeItem(prefix+'_user_first_name');
		localStorage.removeItem(prefix+'_user_last_name');
		localStorage.removeItem(prefix+'_user_id');
		localStorage.removeItem(prefix+'_user_token');
		localStorage.removeItem(prefix+'_user_image');
		localStorage.removeItem(prefix+'_user_modules');
	},
	start: function(data){
		localStorage.setItem(prefix+'_user_first_name', data.first_name || '');
		localStorage.setItem(prefix+'_user_last_name', data.last_name || '');

		localStorage.setItem(prefix+'_user_id', data.id || '');
		localStorage.setItem(prefix+'_user_token', data.token || '');
		localStorage.setItem(prefix+'_user_imagen', data.image || '');
		localStorage.setItem(prefix+'_user_modules', data.modules ? JSON.stringify(data.modules) : null );
	},
	setModules: function(mods){		
		localStorage.setItem(prefix+'_user_modules', mods ? JSON.stringify(mods) : '');
	},
	getModules: _getModules,
	hasModule: (identifier) => {
		return _getModules(false).indexOf(identifier) !== -1;
	},
	get: function(key, isObj){
		var val = localStorage.getItem(prefix+'_user_'+key) || null;
		return (val === "null" || val === "undefined") ? null : ( isObj ? JSON.parse(val) : val);
	},
	exists: function(){
		return localStorage.getItem(prefix+'_user_id') != null;
	},
	getAuthToken: function(){
		return localStorage.getItem(prefix+'_user_token') || '';
	},
	set: function(key, value, isObj){
		localStorage.setItem(prefix+'_user_'+key, isObj ? JSON.stringify(value) : value);
	},
	setFlashValue: (key, value) => {
		sessionStorage.setItem(key, value || '');
	},
	getFlashValue: (key, value) => {
		let v = sessionStorage.getItem(key) || (value !== undefined ? value : '');
		sessionStorage.removeItem(key);
		return v;
	}
};

export default session;