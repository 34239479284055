import React from 'react'
import SearchFilterAntD from "src/app/components/table/search-filter-antd"
import { SearchOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import moment from 'moment'
import { session } from '.';

const utils = {
  getColumnSearchProps: (dataIndex, filters) => {

    var searchInput = null

    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterAntD
          onSearchInput={ node => searchInput = node }
          dataIndex={dataIndex}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, paddingLeft: '12px', paddingRight: '12px' }} />,
      onFilter: (value, record) => true,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      // render: text => `- ${text} -`
      // text => filters && filters[dataIndex] == text ? (
      //   <Highlighter
      //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //     searchWords={[ filters[dataIndex] ]}
      //     autoEscape
      //     textToHighlight={text ? text.toString() : ''}
      //   />
      // ) : (
      //   text
      // ),

    })
  },
  firstValueOnObjectWithArray: (obj, omitItems) => {
    if(obj){
      for(let k in obj){
        if(obj.hasOwnProperty(k)){
          if( Array.isArray(obj[k])){
            if(omitItems && omitItems.length > 0){
              if( !omitItems.includes(k)){
                obj[k] = obj[k][0] || undefined
              }
            }else{
              obj[k] = obj[k][0] || undefined
            }
          }
        }
      }
    }

    return obj
  },
  formatDate: (date, includeTime) => {
    if(date){
      const md = moment(date)
      if(md.isValid()) return md.format(includeTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
    }

    return ""
  },
  filterNavMenuWithModules: ( menuList: Array) => {
    
    return menuList.filter( (menuItem, index) => {

      if(menuItem.module) return session.hasModule(menuItem.module)

      if(menuItem.items){
        
        let filteredChildrens = menuItem.items.filter( (subMenuItem, index2) => {
          if(subMenuItem.module) return session.hasModule(subMenuItem.module)
          return true
        })

        if(filteredChildrens.length > 0){
          menuItem.items = filteredChildrens
          return true
        }

      }

      return true

    })

  },
  getParam: function(props, name){
    return (props &&  props.match && props.match.params) ? props.match.params[name] : null;
  },
  getQuery: (props, name) => {
    const vv = props.location ? queryString.parse(props.location.search) : null;
    return vv ? vv[name] : '';
  },
  randomString: (l) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    var length = l || 10;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  findFirstRepeatedBasicValue: (list) => {

    if(list && list.length > 1){

      for(var i = 0; i < list.length; i++){

        for(var j = i+1; j < list.length; j++){

          if(list[i] === list[j]){

            return list[i]

          }

        }

      }

    }

    return null

  }
}

export default utils