import api from 'src/services/api'

const ActivityTypeService = {
    getAll: (data) => api.get('/v1/admin/activity-type', data),
    getOne: (id) => api.get('/v1/admin/activity-type/'+id),
    create: (data) => api.post('/v1/admin/activity-type', data),
    update: (id, data) => api.put('/v1/admin/activity-type/'+id, data),
    delete: (id) => api.delete('/v1/admin/activity-type/'+id),
}

export default ActivityTypeService