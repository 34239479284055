const constants = {
  authLabels: {
    name: 'SIGEM Administrador',
    description: 'Panel Administrativo para el Sistema de Gestión Empresarial'
  },
  api_url : process.env.REACT_APP_BASE_URL,
  response: {
      error: 'ERROR',
      unknown_error: 'UNKNOWN_ERROR',
      success: 'OK',
  }
}

export default constants;