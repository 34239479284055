import api from 'src/services/api'

const MunicipalityService = {
  getAll: (data) => api.get('/v1/admin/municipality', data),
  create: (data) => api.post('/v1/admin/municipality', data),
  update: (id, data) => api.put('/v1/admin/municipality/'+id, data),
  getOne: (data) => api.put('/v1/admin/municipality', data),
  delete: (id) => api.delete('/v1/admin/municipality/'+id),
}

export default MunicipalityService