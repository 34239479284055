import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import routesMap from "../routesMap";

const UserModule = lazy(() =>import("./modules/UserModule"));
const Users = lazy(() =>import("./modules/Users"));
const Administrators = lazy(() =>import("./modules/Administrators"));
const Countries = lazy(() =>import("./modules/Countries"));
const States = lazy(() =>import("./modules/States"));
const Municipalities = lazy(() =>import("./modules/Municipalities"));
const Localities = lazy(() =>import("./modules/Localities"));
const Accounts = lazy(() =>import("./modules/Accounts"));
const AccountForm = lazy(() =>import("./modules/Accounts/form/account-form"));
const AccountFormEdit = lazy(() =>import("./modules/Accounts/form/account-form"));
const Currencies = lazy(() =>import("./modules/Currency"));
const ActivityTypes = lazy(() =>import("./modules/ActivityType"));

const SystemEvents = lazy(() =>import("./modules/SystemEvents"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={routesMap.dashboard.path} />
        }
        <ContentRoute path={routesMap.dashboard.path} component={DashboardPage} />
        <ContentRoute path={routesMap.builder.path} component={BuilderPage} />

        <Route exact path={routesMap.userModules.path} name={routesMap.userModules.name} component={UserModule} />
        <Route exact path={routesMap.user.path} name={routesMap.user.name} component={Users} />
        <Route exact path={routesMap.accounts.path} name={routesMap.accounts.name} component={Accounts} />
        <Route exact={false} path={routesMap.newAccount.path} name={routesMap.newAccount.name} component={AccountForm}/>
        <Route exact={false} path={routesMap.editAccount.path} name={routesMap.editAccount.name} component={AccountFormEdit}/>
        <Route exact path={routesMap.administrators.path} name={routesMap.administrators.name} component={Administrators} />
        <Route exact path={routesMap.countries.path} name={routesMap.countries.name} component={Countries} />
        <Route exact path={routesMap.states.path} name={routesMap.states.name} component={States} />
        <Route exact path={routesMap.municipalities.path} name={routesMap.municipalities.name} component={Municipalities} />
        <Route exact path={routesMap.localities.path} name={routesMap.localities.name} component={Localities} />
        <Route exact path={routesMap.currency.path} name={routesMap.currency.name} component={Currencies} />
        <Route exact path={routesMap.activityType.path} name={routesMap.activityType.name} component={ActivityTypes} />
        <Route exact={routesMap.systemEvents.exact} path={routesMap.systemEvents.path} name={routesMap.systemEvents.name} component={SystemEvents} />
        
        <Redirect to={routesMap.dashboard.path} />
      </Switch>
    </Suspense>
  );
}
