import api from 'src/services/api'

const StateService = {
  getAll: (data) => api.get('/v1/admin/state', data),
  create: (data) => api.post('/v1/admin/state', data),
  update: (id, data) => api.put('/v1/admin/state/'+id, data),
  getOne: (data) => api.put('/v1/admin/state', data),
  delete: (id) => api.delete('/v1/admin/state/'+id),
}

export default StateService