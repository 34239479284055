const routesMap = {
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
  },
  login: {
    path: '/auth/login',
    name: 'Login'
  },
  terms: {
    path: '/terms',
    name: 'Términos y Condiciones'
  },
  builder: {
    path: '/builder',
    name: 'Configurar tema'
  },
  profile: {
    path: '/profile',
    name: 'Perfil'
  },

  accounts: {
    path: '/accounts',
    name: 'Cuentas'
  },
  newAccount: {
    path: '/accounts/new',
    name: 'Nueva Cuenta',
  },
  editAccount: {
    path: '/accounts/edit/:id',
    name: 'Editar Cuenta',
  },
  
  // user routes
  user: {
    path: '/users',
    name: 'Usuarios'
  },
  userModules: {
    path: '/user-modules',
    name: 'Módulos de usuario'
  },

  // administrator routes
  administrators: {
    path: '/administrators',
    name: 'Administradores'
  },
  countries: {
    path: '/countries',
    name: 'Países'
  },
  states: {
    path: '/states',
    name: 'Estados'
  },
  municipalities: {
    path: '/municipalities',
    name: 'Municipios'
  },
  localities: {
    path: '/localities',
    name: 'Localidades'
  },
  currency: {
    path: '/currency',
    name: 'Monedas'
  },
  activityType: {
    path: '/activity-type',
    name: 'Tipos de actividad'
  },

  systemEvents: {
    path: '/system-events',
    name: 'Eventos del sistema',
    exact: true,
  }
}

export default routesMap;