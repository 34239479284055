import api from 'src/services/api'

const AccountService = {
    getAll: (data) => api.get('/v1/admin/account', data),
    getOne: (id) => api.get('/v1/admin/account/'+id),
    create: (data) => api.post('/v1/admin/account', data),
    update: (id, data) => api.put('/v1/admin/account/'+id, data),
    delete: (id) => api.delete('/v1/admin/account/'+id),

    // modules
    getModules: (id) => api.get('/v1/admin/account/'+id+'/modules'),
    updateModules: (id, data) => api.put('/v1/admin/account/'+id+'/modules', data),
    deleteModule: (id, accountModuleId) => api.delete('/v1/admin/account/'+id+'/modules/'+accountModuleId),

    // users
    
    getPermissionsList: (id) => api.get('/v1/admin/account/'+id+'/permissions'),
    getAllUsers: (id, data) => api.get(`/v1/admin/account/${id}/users`, data),
    createUser: (id, data) => api.post(`/v1/admin/account/${id}/users`, data),
    updateUSer: (id, userId, data) => api.put(`/v1/admin/account/${id}/users/${userId}`, data),
    deleteUser: (id, userId) => api.delete(`/v1/admin/account/${id}/users/${userId}`),
    enabledUser: (id, userId) => api.delete(`/v1/admin/account/${id}/users/${userId}/enabled`),

    generateNewTokenAuth: (id) => api.post(`/v1/admin/account/${id}/token`),
}

export default AccountService